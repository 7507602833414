import YesIcon from 'assets/images/yes-icon.svg';
import QuoteLeft from 'assets/images/quote-left.svg';
import SuccessImage from 'assets/images/create-success.svg';
import CancelRenewalImage from 'assets/images/cancel-renewal-v2.svg';
import CanceSubslImage from 'assets/images/sad-smiley.svg';
import ThumbsupImage from 'assets/images/thumbsup-smiley.svg';
import Logo from 'assets/images/logo.svg';
import Theme from 'theme';

const {
  REACT_APP_GROUP_ID: groupId,
  REACT_APP_AWS_REGION: region,
  REACT_APP_COGNITO_POOL_ID: userPoolId,
  REACT_APP_COGNITO_WEB_CLIENT_ID: userPoolWebClientId,
  REACT_APP_CLIENT_HEADER: clientHeader = groupId,
  REACT_APP_APPPOINTMENT_LINK: bookAppointmentLink,
} = process.env;

const config = {
  groupId,
  region,
  userPoolId,
  userPoolWebClientId,
  logo: Logo,
  cptCodesIcon: YesIcon,
  portalName: 'westwindintegratedhealth',
  tokenName: 'westwindintegratedhealthSubscriberToken',
  globalClass: 'westwindintegratedhealth-container',
  clientHeader,
  enableHeroButton: true,
  pricingType: 'standard-pricing',
  heroLocaleColor: 'secondary',
  headerProps: {
    enable: false,
  },
  homeTemplateProps: {
    heroSection: {
      variant: 2,
      titleClasses: 'home-hero-title',
      subTitleClasses: 'home-hero-subtitle',
      primaryButtonProps: {
        to: '/find-a-provider',
      },
      secondaryButtonProps: {
        to: '/login',
        color: 'primary',
      },
      background: '/images/home-images/home-banner-img.webp',
    },
    benefitsSection: {
      variant: 4,
      columns: 3,
      alignTitle: 'left',
      alignCard: 'left',
    },
    subscriptionSection: {
      tabVariant: 2,
      carouselResponsiveItems: {
        desktop: 3,
      },
      showAddons: false,
      primaryButtonProps: {
        color: 'primary',
      },
      addonProps: {
        showCptCodes: true,
        addonVariant: 2,
      },
    },
    howItWorksSection: {
      variant: 1,
      heroImage: '/images/home-images/how-it-work.webp',
    },
    testimonialSection: {
      variant: 3,
      quoteImage: QuoteLeft,
      messageType: 'h6',
      gridSpacing: {
        xSmall: 12,
        medium: 12,
      },
      carouselResponsiveItem: {
        superLarge: 1,
        large: 1,
      },
    },
    staticHeroSection: {
      enable: true,
      variant: 1,
      primaryButtonProps: {
        color: 'primary',
      },
    },
  },
  checkoutSection: {},
  findProviderSection: {
    locationWrapper: 7,
    mapWrapper: 5,
  },
  choosePlanSection: {
    sectionSubtitle: '',
    locationLogo: Logo,
    cardVariant: 2,
    tabVariant: 2,
    planCardProps: {
      buttonProps: {
        normalColor: 'primary',
        highlightedColor: 'primary',
        normalVariant: 'contained',
        highlightedVariant: 'outlined',
      },
    },
    staticHeroSection: {
      enable: true,
      variant: 1,
    },
  },
  subscriptionSuccess: {
    successImage: SuccessImage,
    enableBookAppointment: true,
    bookAppointmentProps: {
      link: bookAppointmentLink,
      target: '_blank'
    },
  },
  savingsSummary: {
    enableFooterbar: true,
    showCategoryHeaders: true,
  },
  faqSection: {
    heroProps: {
      secondaryButtonProps: {
        variant:'outlined',
        color: "secondary"
      }
    }
  },
  dashboardProps: {
    showBenefitsUsage: true,
    cancelRenewal: {
      image: CancelRenewalImage,
    },
    cancelSubscription: {
      image: CanceSubslImage,
    },
    reactivateRenewal: {
      image: ThumbsupImage,
    },
  },
  bookAppointment: {},
  footer: {
    enable: false,
  },
  loginPage: {
    bannerImage: './images/login.webp',
  },
  theme: Theme,
};

export default config;
