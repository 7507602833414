import { palette } from './palette';
import background from 'assets/images/faq-banner-img.webp'

export const homeStyle = {
  /* Login - Styles */
  '.login-root': {
    '& .hero-shaped__wrapper': {
      maxWidth: 'unset',
      minHeight: 'unset !important',

      '& .hero-shaped__left-side': {
        flex: '0 0 60%',
        maxWidth: '60%',

        '@media(min-width: 900px) and (max-width: 1200px)': {
          flex: '0 0 50%',
          maxWidth: '50%',
        },

        '& .hero-shaped__image': {
          width: '60vw',

          '@media(min-width: 900px) and (max-width: 1200px)': {
            width: '50vw',
          },
        },
      },
    },
  },

  '.westwindintegratedhealth-container': {
    '& .normal-text': {
      fontWeight: 300,
    },

    '& .savings-hero-section, .checkout-hero-section, .plan-selection-section, .faq-hero-section, .dashboard-hero-section, .find-provider-section, & .terms-hero-section, & .privacy-hero-section, & .hipaa-hero-section':
      {
        background: palette.background.header,
      },

    '& .checkout-outer-container, .plan-selection-container': {
      '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
        border: `1px solid ${palette.primary.main}`,
        opacity: 1,
      },
      '& .MuiSwitch-track': {
        border: `1px solid ${palette.primary.main}`,
        background: palette.text.highlight,
      },
    },

    /* App main - styles */
    '& .app-main-content': {
      paddingTop: 0,
    },

    /* Hero Section - Styles */
    '& .home-hero-wrapper': {
      backgroundColor: palette.text.highlight,

      '& .hero-background': {
        objectFit: 'cover',
        '@media screen and (max-width: 900px)': {
          height: '340px',
        },
      },
      '& .hero-section': {
        display: 'flex',
        alignItems: 'center',
        maxHeight: '550px',
        '& .section-header': {
          '@media screen and (min-width: 768px)': {
            marginTop: '60px',
          },
        },
      },
      '& .hero-title': {
        fontWeight: 'normal'
      },
      '& .home-hero-subtitle': {
        maxWidth: 510,
      },
    },

    /* Benefits - Styles */
    '& .definition-variant-3': {
      '& .benefits-wrapper': {
        '& .section-header__title': {
          fontSize: '40px'
        },
      },
      '& #definition-section': {
        '& .benefits-wrapper': {
          padding: '0 24px',
        },
      },
    },

    /* Pricing & Benefits / Subscription - Styles */
    '& .subscription-wrapper': {
      color: palette.text.highlight,
      '& .subscription-bg': {
        height: '100%',
        background: palette.secondary.main,
      },
      '& .section-header__title, & .section-header__subtitle': {
        color: palette.text.highlight,
      },
      '& #pricing-benefit-section': {
        '& .planCard': {
          '&:hover': {
            border: `1px solid ${palette.text.primary}`,
            color: palette.text.primary,
            background: palette.text.highlight,
          },
          '& .highlight-divider': {
            backgroundColor: palette.text.disabled,
          },
          '& .price-value': {
            color: palette.text.primary,
            fontWeight: 'bold'
          },
        },
        '& .savings-summary-link': {
          textDecoration: 'underline',
          color: palette.primary.main,
          cursor: 'pointer',
        },
      },
    },

    /* How it works - Styles */
    '& .howitworks-section': {
      background: palette.background.main,

      '& .howitworks-wrapper': {
        padding: 20,
      },

      '& .howitworks-wrapper-left': {
        padding: 20,
      },

      '& .makeStyles-root-54': {
        padding: 0,
      },

      '& .how-cover-image': {
        backgroundSize: 'cover',
      },

      '& .step-counter': {
        borderRadius: '16%',
        backgroundColor: palette.background.secondaryLight,
        color: palette.text.primary,
      },

      '& .step-started-button': {
        '& .right-icon': {
          color: palette.text.highlight,
        },
      },

      '& .working-steps-container:not(:last-child)': {
        paddingBottom: 30,
      },

      '& .step-title': {
        fontWeight: 'normal'
      },

      '@media screen and (max-width: 900px)': {
        '& .howitworks-wrapper-left': {
          padding: '5px',
        },
      },

      '@media(min-width: 900px)': {
        '& .howitworks-wrapper-left': {
          paddingLeft: '100px',
        },
      },
    },

    /* Testimonial - Styles */
    '& .testimonial-wrapper': {
      background: palette.background.header,
      '& .testimonial-name': {
        color: palette.text.highlight
      }
    },

    /* Savings Summary */
    '& .savings-wrapper': {
      '& .section-header': {
        '& .section-header__title': {
          color: palette.text.primary,
        },
        '& .section-header__subtitle': {
          color: palette.text.primary,
        },
      },
      '& .service-plan-table': {
        '& .savings-topheader': {
          color: `${palette.text.highlight} !important`,
        },

        '& .savings-header': {
          color: palette.text.primary,
        },

        '& .savings-subheader': {
          backgroundColor: palette.background.main,
          color: palette.text.primary,
          fontWeight: 600,
        },
      },

      '& .savings-contact-card': {
        background: palette.background.footer,
      },
    },

    /* Static hero banner */
    '& .home-static-hero-wrapper': {
      '& .hero-static-bg': {
        backgroundColor: palette.text.highlight,
      },
    },

    /* Book Appointment */
    '& .morning-image, & .noon-image, & .evening-image': {
      fill: `${palette.primary.main} !important`,
    },

    /* Create Subscriber - Checkout flow container */
    '& .checkout-flow-container': {
      '& .provider-details': {
        border: `1px solid ${palette.text.border}`,
      },
      '& .addon-bg': {
        background: palette.background.secondaryLight,
      },
      '& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
        background: palette.background.light,
        border: `1px solid ${palette.primary.main}`,
        opacity: 1,
      },
      '& .MuiSwitch-track': {
        border: `1px solid ${palette.primary.main}`,
        background: palette.text.highlight,
      },
    },

    /* Create Subscriber - Choose plan */
    '& .plan-selection-container': {
      '& .plans-wrapper': {
        '& .planCard': {
          border: `1px solid ${palette.text.primary}`,
          '& .price-value, .display-frequency': {
            color: palette.secondary.main,
          },
          '&:hover': {
            color: palette.text.primary,
            background: palette.text.highlight,
          },
          '& .highlight-divider': {
            backgroundColor: palette.text.highlight,
          },
          '& .planCardDescription': {
            height: 'calc(100% - 24px)',
          },
        },
      },
      '& .primary-static-button': {
        background: palette.primary.main,
      },
      '& .react-multi-carousel-dot button': {
        background: palette.primary.main,
      },
    },

    /* FAQ */
    '& .faq-hero-section': {
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',

      '& .hero-header-title, .hero-header-subtitle': {
        margin: '0',
        textAlign: 'left',
      },
      '& .header-container': {
        justifyContent: 'left',
        margin: '0',
      },
    },
    '& .faq-contact-section': {
      '& .contact-img-wrapper': {
        width: '64px',
        maxWidth: '64px',
      },
    },

    /* Create Subscriber - Checkout */
    '& .checkout-outer-container': {
      '& .payment-title-root': {
        marginTop: 0,
      },
      '& .promo-spinner': {
        backgroundColor: 'unset',
      },
      '& .termsText': {
        fontFamily: 'auto',
        fontWeight: 'bold'
      }
    },

    /* Contact Card - FAQ, Success Page */
    '& .faq-contact-wrapper': {
      background: palette.background.footer,
    },
    '& .mail-link': {
      color: palette.text.primary,
    },

    /* Footer Disclosure */
    '& .footer-wrapper, & .footer-wrapper a': {
      background: palette.text.highlight,
    },

    /* Dashboard Page - Appointment Header */
    '& .MuiTableRow-root .MuiTableCell-head': {
      color: palette.text.highlight,
    },

    /* Stepper - styles */
    '& .customStepperRoot': {
      '& .MuiStepConnector-lineHorizontal': {
        borderColor: palette.background.alternate,
      },
      '& .stepperIconActive': {
        color: `${palette.primary.main} !important`,
        '& .stepperIconText': {
          fill: palette.text.highlight,
          }
      },
      '& .stepperIconRoot': {
        color: 'transparent',
        borderColor: palette.primary.main,
        '& .stepperIconText': {
          fill: palette.primary.main,
          }
      },
      '& .stepperIconCompleted': {
          color: palette.primary.main
        }
    },
  },
};