export const palette = {
  alternate: {
    main: '#F9F6FF',
    light: '#F9F8F8',
    dark: '#E5EAF4',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  type: 'light',
  primary: {
    main: '#91BC25',
    light: '#f4f8e9'
  },
  secondary: {
    main: '#092644',
    contrastText: '#0A5285',
    light: '#D9EDF7'
  },
  text: {
    primary: '#111111',
    secondary: '#3D4956',
    alternate: '#23B0E7',
    disabled: '#CCC',
    highlight: '#FFF',
    success: '#1CA179',
    border: '#E5EAF4',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    main: '#FFFFFF',
    paper: '#FFF',
    default: '#FFF',
    header: 'linear-gradient(to right, rgba(41, 187, 229, 0.6), rgba(41, 120, 205, 0.6))',
    footer: 'linear-gradient(to right, rgba(41, 187, 229, 0.6), rgba(41, 120, 205, 0.6))',
    level2: '#f7f8fb',
    level1: '#FFF',
    tableHead: '#152435',
    tableSubhead: '#F4FAF7',
    tableBorder: '#7D808880',
    tableAlternate: '#FFFFFF',
    alternate: '#c8dd92',
    success: '#DBEFDC',
    error: '#FFEAEA',
    primary: '#91BC25',
    secondary: '#F8D859',
    light: '#deeabd',
    secondaryLight: 'rgba(238, 246, 250, 0.6)'
  },
  verticalBar: {
    used: '#91BC25',
    unused: '#7BD6D01a',
    unlimited: '#C0C0C0',
  }
};